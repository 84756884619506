<template>
	<div>
		<div class="flex header">
			<h2>岗亭收入明细</h2>
			<el-button @click="$refs.importFile.importFile=true">岗亭收入信息导入</el-button>
		</div>
		<el-form ref="form" class="searchCriteria" label-suffix=":" :model="searchForm" label-width="90px">
			<div class="flex mb-1  ">
				<el-form-item label="停车场">
					<el-select v-model="searchForm.parkId" placeholder="请选择停车场" @change="handleSelectChange">
						<el-option label="全部" value=""></el-option>
						<el-option :label="item.parkingName" :key="ind" v-for="(item,ind) in parkingList"
											 :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="用户类型">
					<el-select v-model="searchForm.memberTypeId" placeholder="请选择用户类型">
						<el-option label="全部" value=""></el-option>
						<el-option :label="item.name" :key="ind" v-for="(item,ind) in memberTypeList" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="车辆类型">
					<el-select v-model="searchForm.carType" placeholder="请选择车辆类型">
						<el-option label="全部" value=""></el-option>
						<el-option :label="item.label" :key="ind" v-for="(item,ind) in carTypeSel" :value="item.val"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="flex mb-1  ">
				<el-form-item label="收费人">
					<!--<el-select v-model="searchForm.tollSentry" placeholder="请选择收费人">
						<el-option :label="item.label" :key="ind" v-for="(item,ind) in tollSentrySel" :value="item.val"></el-option>
					</el-select>-->
					<el-input v-model="searchForm.tollSentry" placeholder="请填写收费人"></el-input>
				</el-form-item>
				<el-form-item label="收费处">
					<el-select v-model="searchForm.chargeStation" placeholder="请选择收费处">
						<el-option label="全部" value=""></el-option>
						<el-option :label="item.name" :key="ind" v-for="(item,ind) in chargeStationSel"
											 :value="item.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="收费方式">
					<el-select v-model="searchForm.chargeType" placeholder="请选择收费方式">
						<el-option label="全部" value=""></el-option>
						<el-option :label="item.name" :key="ind" v-for="(item,ind) in chargeTypeSel" :value="item.name"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="flex ">

				<el-form-item label="收费时间">
					<el-date-picker
							v-model="searchForm.startDate"
							type="datetime"
							placeholder="开始时间">
					</el-date-picker>
					--
					<el-date-picker
							v-model="searchForm.endDate"
							type="datetime"
							placeholder="结束时间">
					</el-date-picker>
				</el-form-item>
				<div class="averageWid btnGroup">
					<el-button @click="searchData">查询</el-button>
					<el-button @click="reloadSearch">重置</el-button>
				</div>
			</div>
		</el-form>
		<div class="table">
			<el-table
					v-loading="loading"
					element-loading-text="拼命加载中"
					element-loading-spinner="el-icon-loading"
					element-loading-background="rgba(0, 0, 0, 0.8)"
					:data="tableData"
					:header-cell-style="{'background':'rgba(0, 103, 166, 0.3)','color': '#ffffff','fontWeight':'bold','textAlign':'center'}"
					height="calc(100vh - 430px)"
					style="width: 100%">
				<el-table-column align="center"
												 label="ID"
												 width="80">
					<template slot-scope="scope">
						{{ scope.row.parkId ? scope.row.oldId : "-" }}
					</template>
				</el-table-column>
				<el-table-column
						prop="tollSentry" align="center"
						label="收费人"
						width="100">
				</el-table-column>
				<el-table-column
						prop="chargeStation" align="center"
						label="收费处">
				</el-table-column>
				<el-table-column
						prop="carNo" align="center"
						label="车牌号码"
						width="100">
				</el-table-column>
				<el-table-column
						prop="memberType" align="center"
						label="用户类型"
						width="80">
				</el-table-column>
				<el-table-column
						prop="carType" align="center"
						label="车辆类型"
						width="80">
				</el-table-column>
				<el-table-column align="center"
												 label="进场时间"
												 width="140">
					<template slot-scope="scope">
						{{ scope.row.inTime|formatTimer }}
					</template>
				</el-table-column>
				<el-table-column align="center"
												 label="出场时间"
												 width="140">
					<template slot-scope="scope">
						{{ scope.row.outTime|formatTimer }}
					</template>
				</el-table-column>
				<el-table-column
						prop="stopTime" align="center" show-overflow-tooltip
						label="停车时长"
						width="120">
				</el-table-column>
				<el-table-column
						prop="needChargeAmount" align="center"
						label="应收金额">
				</el-table-column>
				<el-table-column
						prop="realChargeAmount" align="center"
						label="实收金额">
				</el-table-column>
				<el-table-column
						prop="parkingTicket" align="center"
						label="停车票">
				</el-table-column>
				<el-table-column align="center" show-overflow-tooltip
												 label="停车票类型" width="100">
					<template slot-scope="scope">
						{{ scope.row.parkingTicketType ? scope.row.parkingTicketType : "-" }}
					</template>
				</el-table-column>
				<el-table-column align="center" show-overflow-tooltip
												 label="停车票票号" width="100">
					<template slot-scope="scope">
						{{ scope.row.parkingTicketNo ? scope.row.parkingTicketNo : "-" }}
					</template>
				</el-table-column>
				<el-table-column
						prop="chargeType" align="center" show-overflow-tooltip
						label="收费方式">
				</el-table-column>

				<el-table-column show-overflow-tooltip
												 prop="remark" align="center"
												 label="备注">
				</el-table-column>
				<el-table-column show-overflow-tooltip
												 prop="orderNo" align="center"
												 label="订单号">
				</el-table-column>
				<el-table-column
						prop="chargeParking" align="center" show-overflow-tooltip
						label="收费停车场" width="120">
				</el-table-column>
				<el-table-column
						prop="handler" align="center" show-overflow-tooltip
						label="办理人" width="120">
				</el-table-column>
			</el-table>
			<el-pagination
					class="pagination"
					@current-change="handleCurrentChange"
					:current-page="page.current"
					:page-size="page.size"
					layout="prev, pager, next, total"
					:total="page.total">
			</el-pagination>
		</div>
		<!--导入-->
		<importFile ref="importFile" :uploadUrl="uploadUrl" @reflush="reloadSearch"></importFile>
	</div>
</template>

<script>
import { getSentryIncomeInfoList, getCarParkInfoList } from "@/api/api";
import moment from "moment";

moment.locale("zh-cn");

export default {
	name: "incomeDetails",
	data() {
		return {
			uploadUrl: '/car/sentry-income-info/import',
			searchForm: {
				chargeParking: "",
				ownerCompany: "",
				memberTypeId: "",
				startDate: "",
				endDate: "",
				parkId: "",
				chargeStation: "",
				tollSentry: "",
				carType: "",
				chargeType: ""
			},
			carTypeSel: [{ val: "small", label: "small" }, { val: "large", label: "large" }],
			chargeStationSel: [],
			chargeTypeSel: [],
			tableData: [],
			page: {
				current: 1,
				size: 10,
				total: 0
			},
			memberTypeList: [],
			parkingList: [],
			loading:false
		};
	},
	mounted() {
		this.getData();
		this.getMemberInfoTypeList();
		this.getParkingList();
		this.getChargingMethod();
	},
	methods: {
		searchData(){
			this.page.current = 1
			this.getData();
		},
		handleSelectChange(val){
			this.searchForm.chargeStation = ""
			this.$get('/car/parking-gate/'+val).then(res=>{
				this.chargeStationSel = res.data.data
			})
		},
		getData() {
			this.loading = true
			this.searchForm.page = this.page.current;
			this.searchForm.size = this.page.size;
			if (this.searchForm.startDate != "" && this.searchForm.endDate != "") {
				this.searchForm.startDate = moment(this.searchForm.startDate).format("YYYY-MM-DD HH:mm:ss");
				this.searchForm.endDate = moment(this.searchForm.endDate).format("YYYY-MM-DD HH:mm:ss");
			}

			getSentryIncomeInfoList(JSON.stringify(this.searchForm)).then(res => {
				this.tableData = res.data.rows;
				this.page.total = res.data.total;
				setTimeout(()=>{
					this.loading = false
				},200)

			}).catch(err => {
				console.log("err: " + err);
			});
		},
		handleCurrentChange(val) {
			this.page.current = val;
			this.getData();
		},
		getMemberInfoTypeList() {
			this.$get("/car/memberInfoType/list").then(res => {
				this.memberTypeList = res.data.data;
			});
		},
		getParkingList() {
			let params = {
				page: this.page.current,
				size: 9999
			};
			getCarParkInfoList(JSON.stringify(params)).then(res => {
				this.parkingList = res.data.rows;
			});
		},
		getChargingMethod(){
			this.$get('/car/sentry-charging-method/list').then(res=>{
				this.chargeTypeSel = res.data.data
			})
		},
		reloadSearch() {
			this.searchForm = {
				chargeParking: "",
				ownerCompany: "",
				memberTypeId: "",
				startDate: "",
				endDate: "",
				parkId: "",
				chargeStation: "",
				tollSentry: "",
				carType: "",
				chargeType: ""
			};
			this.page.current=1
			this.getData();
		}
	},
	components: {
		"importFile": () => import("../member/importFile")
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/table";
	body{
		margin: 0;
	}
</style>
